// Step 1: Import React
import * as React from 'react'
import Layout from '../components/layout'
import { container } from '../components/layout.module.css'
import { Link } from 'gatsby';
import { StaticImage } from "gatsby-plugin-image"

// Step 2: Define your component
const LearnPage = () => {
  return (
    <div className={container}>
      <main>
        <Layout>

          <StaticImage src="../../static/1-learn.jpg" alt="Get Into Investing!" layout="constrained"/>
 
          <table align="center" width="90%">
            <tr>
              <td>
              <div><br />
                <p>
                  <center>
                    <h2>
                      Are you ready to learn simple<br /> changes to your daily routine?
                    </h2>
                  </center></p><br />
                  <p>
                    The story behind <i>Get Into Investing!</i> begins with my seventeen year old son asking me how old someone has to be to invest in the stock market. As a teenager myself once pondering that same question, it took me about thirty years to finally figure some things out. <i>Get Into Investing!</i> was written primarily for my son's generation to have a baseline for learning this complex topic, while utilizing the technology in their pocket. 
                  </p>
                  <p>
                  There are dozens of people that will tell you their horror stories about investing in the stock market, but those people may also not remember how much money they made. Don't let others decide your returns and focus on learning strategies that will keep you gaining for many years to come. 
                  </p>
                  <p>
                  <i>Get Into Investing!</i> teaches some ground rules, gives you a few tips that you can use right away and a few stories about simple investing strategies to explain topics of interest. Industry references are based on the casino game blackjack and the stories will help you see what others are doing with their financial choices.
                  </p>
                  <p>
                  The book is broken down into three sections:
                    <ul>
                      <li>Understanding risk</li>
                      <li>Assessing investments</li>
                      <li>Reinforce positive habits</li>
                    </ul>
                  </p>
                  <p>
                  <i>Get Into Investing!</i> includes stock information on companies that were invested in over the years and lessons that cost money. These perspectives are shared for you to learn from them and capitalize immediately, without having to make your own mistakes. 
                  </p>
                  <p>
                  There are multiple books on investing out there that can spend hundreds of pages teaching you options trading, high-risk trading and other complex topics. <i>Get Into Investing!</i> is the only one that shows you simple daily smartphone tasks that will help you passively create wealth. 
                  </p>
                  <p>
                  You can get started with an investment of only twenty dollars to be calling yourself a beginning stock investor by the end of the week. Click below for additional information, links to online vendors and social media outlets.
                  </p>
                  <p>
                  What are you waiting for? It's time to <i>Get Into Investing!</i>
                  </p>
              </div>
              <div align="center">
                  <p>
                    <>  <Link className="btn btn-outline-secondary" to="/learn2"> Learn More </Link> </>
                    <>  <Link className="btn btn-outline-secondary" to="/buy"> Buy Literature </Link> </>
                    <>  <Link className="btn btn-outline-secondary" to="/social"> Social Media </Link> </>
                  </p>
              </div>
              </td>  
            </tr>
          </table>

        </Layout>
      </main>
    </div>
  )
}

// Step 3: Export your component
export default LearnPage